import React from 'react'
import './Info.css'

export default function Info() {
    return (
        <div className="card-info">
            <h3 className="card-info__name">Satinder Singh</h3>
            <p className="card-info__title">Frontend Developer</p>
            <a href="https://roar-webdesign.co.uk/" target="_blank" rel="noreferrer" className="card-info__link">roar-webdesign.co.uk</a>

            <div className="card-info__actions">
                <a href="mailto:SatSingh07@hotmail.com" target="_blank" rel="noreferrer" className="card-info__email BTN"><i class="fa-solid fa-envelope"></i>Email</a>
                <a href="https://www.linkedin.com/in/satinder-singh-9535a5215/" target="_blank" rel="noreferrer" className="card-info__linkedIn BTN"><i class="fa-brands fa-linkedin"></i>LinkedIn</a>
            </div>
        </div>
    )
}