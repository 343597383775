import React from 'react'
import './Socials.css'

export default function Social() {
    return(
        <ul className="card-socials">
            
            {/* Twitter */}
            <li className="card-socials__item">
                <a href="https://twitter.com/" target="_blank" rel="noreferrer"><i class="fa-brands fa-square-twitter"></i></a>
            </li>

            {/* Facebook */}
            <li className="card-socials__item">
                <a href="https://facebook.com/" target="_blank" rel="noreferrer"><i class="fa-brands fa-square-facebook"></i></a>
            </li>

            {/* Instagram */}
            <li className="card-socials__item">
                <a href="https://instagram.com/" target="_blank" rel="noreferrer"><i class="fa-brands fa-square-instagram"></i></a>
            </li>

            {/* GitHub */}
            <li className="card-socials__item">
                <a href="https://github.com/" target="_blank" rel="noreferrer"><i class="fa-brands fa-square-github"></i></a>
            </li>

        </ul>
    )
}