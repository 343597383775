import React from 'react'
import './Bio.css'

export default function Bio() {
    return (
        <div className="card-bio">
            <h3 className="card-bio__title">About</h3>

            <p className="card-bio__text">I'm a Frontend Developer with a passion for building high quality user experiences. I have extensive experience in WordPress and specialise in HTML, CSS (Preprocessors), JavaScript, JQuery and React. </p>

            <h3 className="card-bio__title">Interests</h3>

            <p className="card-bio__text">Football Fanatic - Red Devil at heart. All things Sci-Fi, Punjabi music, massive foodie and a good cup of Java never goes unappreciated!</p>
        </div>
    )
}