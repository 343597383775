import React from 'react';
import './App.css';
import Avatar from './Avatar';
import Info from './Info';
import Bio from './Bio';
import Socials from './Socials';

export default function App() {
    return(
        <div id="card">
            <Avatar />
            <Info />
            <Bio />
            <Socials />
        </div>
    );
};
